import React, { useState, useEffect } from "react"
import LazyLoad from 'react-lazyload';
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/Container"
import SEO from "../components/seo"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ComponentExample from '../components/ComponentExample'
import CtaRightRailDetail from '../components/CtaRightRailDetail'
import options from '../components/linkOptions'

// graphql is at bottom

import client from '../api/contentful'


const ComponentPage = ({data}) => {
    const doc = data.contentfulComponentPage;
    const[content, setContent] = useState();
    const[allTemplates, setAllTemplates] = useState();
    const[dynamicPlaceholders, setDynamicPlaceholders] = useState();

    useEffect(() => {
        client.getEntries({
            content_type: 'componentPage',
            'fields.slug[in]': doc.slug,
        })
        .then(entry => setContent(entry.items[0]))
        .catch(err => console.log(err));

        client.getEntries({
            content_type: 'pageLayout',
        })
        .then(entry => setAllTemplates(entry))
        .catch(err => console.log(err));

    }, [doc.slug])

    useEffect(() => {
        const alphabetizeList = (list) => {
            let newList = list.sort(function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            })
            return newList
        }

        if(allTemplates) {
            let temp = []
            allTemplates.items.forEach(template => {
                let templateObj = {
                    name: template.fields.name,
                    slug: template.fields.slug,
                    placeholders: []
                }
    
                if(template.fields.header) {
                    const headerP = template.fields.header.filter(x => x.fields.slug === doc.slug)
                    if(headerP.length) {
                        templateObj.placeholders.push(template.fields.headerPlaceholder)
                    }
                }
                if(template.fields.heroComponents) {
                    const heroComponentsP = template.fields.heroComponents.filter(x => x.fields.slug === doc.slug)
                    if(heroComponentsP.length) {
                        templateObj.placeholders.push(template.fields.heroPlaceholder)
                    }
                }
                if(template.fields.leftRailComponents) {
                    const leftRailComponentsP = template.fields.leftRailComponents.filter(x => x.fields.slug === doc.slug)
                    if(leftRailComponentsP.length) {
                        templateObj.placeholders.push(template.fields.leftRailPlaceholder)
                    }
                }
                if(template.fields.rightRailComponents) {
                    const rightRailComponentsP = template.fields.rightRailComponents.filter(x => x.fields.slug === doc.slug)
                    if(rightRailComponentsP.length) {
                        templateObj.placeholders.push(template.fields.rightRailPlaceholder)
                    }
                }
                if(template.fields.pageContentComponents) {
                    const pageContentComponentsP = template.fields.pageContentComponents.filter(x => x.fields.slug === doc.slug)
                    if(pageContentComponentsP.length) {
                        templateObj.placeholders.push(template.fields.pageContentPlaceholder)
                    }
                }
                if(template.fields.prefooterComponents) {
                    const prefooterComponentsP = template.fields.prefooterComponents.filter(x => x.fields.slug === doc.slug)
                    if(prefooterComponentsP.length) {
                        templateObj.placeholders.push(template.fields.prefooterPlaceholders)
                    }
                }
    
                temp.push(templateObj)

                
            })

            setDynamicPlaceholders(alphabetizeList(temp))
    
        }
        }, [allTemplates, doc.slug])

    if (!doc || !content || !dynamicPlaceholders) return null;

    const componentPlaceholders = dynamicPlaceholders.map((template, index) => {
        if(template && template.placeholders.length) {
            return (
                <div key={index} className="list-group-item flex-column align-items-start pl-0 py-3">
                        <div className="d-flex w-100 justify-content-between mb-1">
                        <Link key={index} to={`/templates/${template.slug}/`} className="text-decoration-none"><h3 className="h5 mb-1">{template.name}</h3></Link>
                        </div>
                        <small className="text-muted text-uppercase">Placeholders <span>({template.placeholders.length})</span></small>
                        <ul className="m-0 mt-1 p-0 list-unstyled">{template.placeholders.map((placeholder, index) => { return <li className="mx-0 px-0" style={{left: 0}} key={index}>{placeholder}</li>})}</ul>
                    </div>
            )
        } else {
            return null
        }
    })

    let componentHTML;
    try {
        componentHTML = require(`uta-prototype/bundle/html/${content.fields.uid}.html`);
    } catch(e) {
        componentHTML = '<p>Component not available for previewing</p>';
    }

    let componentVariationHTML;
    try {
        componentVariationHTML = require(`uta-prototype/bundle/html/${content.fields.componentUidVariation}.html`);
    } catch(e) {
        componentVariationHTML = '<p>Component not available for previewing</p>';
    }

    const formatTutorialStartTime = (startTime) => {
        if(content.fields.componentTutorial.tutorialVideoStartTime) {
            let newStart = startTime.split(":");
            let minute = +newStart[0]*60;
            let seconds = +newStart[1];
            return(minute+seconds);
        } else {
            return null
        }
    }

    return (
        <Layout>
            <SEO title={content.fields.name} />
            
            <Container>
            <section className="page-content col component-template">
                <div className="row">
                    <div className="col-12">
                        <section className="hero">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 offset-lg-1">
                                        <h1 className="hero-title">{content.fields.name}</h1>
                                        <div className="hero-description"><strong>Type:</strong> {content.fields.category} | <strong>Status:</strong> {content.fields.status}</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="col-lg-9 mt-0 pt-0 mx-auto">

                        {!content.fields.globalComponent && content.fields.useCases &&
                            <div className="richtext use-cases py-3">
                                <h2>About the component</h2>
                                {documentToReactComponents(content.fields.useCases, options)}
                            </div>
                        }

                        {!content.fields.globalComponent && dynamicPlaceholders && dynamicPlaceholders.length > 0 &&
                            <div className="richtext placeholders py-3">
                                <h2>Templates</h2>
                                <div className="list-group list-group-flush border-bottom">
                                    {componentPlaceholders}
                                </div>
                            </div>
                        }

                        {!content.fields.globalComponent && content.fields.imageSizes &&
                            <div className="richtext placeholders mt-2 py-3">
                                <h2>Image Sizes</h2>
                                {content.fields.imageSizes.length > 0 &&
                                <ul>
                                {content.fields.imageSizes.map(size => (
                                    <li key={`${size.fields.width}x${size.fields.height}`}><strong>{size.fields.width}x{size.fields.height}</strong> <span className="text-muted">pixels</span></li>
                                ))}
                                </ul>}
                            </div>
                        }
                    </div>

                    <div className="col-lg-3">
                        {!content.fields.globalComponent && <CtaRightRailDetail
                        headline="Component Details">
                            <p><strong>Rich Text:</strong><br /> {content.fields.richtext ? 'Yes' : 'No'}</p>
                            <p><strong>Max # of Images:</strong><br /> {content.fields.images > 0 ? content.fields.images : 'Images are not supported'}</p>
                            <p><strong>Buttons:</strong><br /> {content.fields.buttons > 0 ? content.fields.buttons : 'Buttons are not supported'}</p>
                            <p><strong>Links:</strong><br /> {content.fields.links}</p>
                            <p><strong>Listing:</strong><br /> {content.fields.listing}
                            {content.fields.listing === 'Limited' && content.fields.listLimit &&
                                ` to ${content.fields.listLimit}`
                            }
                            </p>
                        </CtaRightRailDetail> }

                        {content.fields.componentTutorial && <CtaRightRailDetail
                        category="Tutorials"
                        headline={content.fields.componentTutorial.fields.title}
                        buttonURL={`/tutorials/${content.fields.componentTutorial.fields.slug}`}
                        buttonText="Read the full tutorial">
                            <div className="pt-3 pb-4" style={{maxWidth: '640px'}}><div style={{position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden'}}><iframe width="640" height="360" title={`Component tutorial for ${content.fields.name}`}src={`https://web.microsoftstream.com/embed/video/${content.fields.componentTutorial.fields.tutorialVideoId}?autoplay=false&amp;showinfo=true;${content.fields.componentTutorial.fields.tutorialVideoStartTime ? `&st=${formatTutorialStartTime(content.fields.componentTutorial.fields.tutorialVideoStartTime)}` : ''}`} allowFullScreen style={{border:'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: '100%', maxWidth: '100%'}}></iframe></div></div>
                        </CtaRightRailDetail>}

                    </div>
                </div>

                <LazyLoad height={500} offset={100}>
                    <div className="my-5">
                        <h2 className="mb-4 text-center">Component Example</h2>
                        <ComponentExample htmlFile={componentHTML} defaultMobile={content.fields.defaultMobile ? 'mobile' : 'desktop'}/>
                    </div>
                </LazyLoad>
                
                
                {content.fields.componentUidVariation &&
                <LazyLoad height={500} offset={100}>
                    <div className="my-5">
                        <h2 className="mb-4 text-center">Component Variation</h2>
                        <ComponentExample htmlFile={componentVariationHTML} defaultMobile={content.fields.defaultMobile ? 'mobile' : 'desktop'}/>
                    </div>
                </LazyLoad>
                 }

                {content.fields.similarComponents &&
                <section className="related-links">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col" style={{padding: '0'}}>
                                <div className="related-links-wrapper">
                                    <h2 className="related-links-title">Similar Components</h2>
                                    <div className="uta-btn-group">
                                        {content.fields.similarComponents.map((component, index) => (
                                            <Link key={index} role="button" className="uta-btn uta-btn-inverse-ghost-secondary" to={`/components/${component.fields.slug}`}>{component.fields.name}</Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }

            </section>
            </Container>
            
        </Layout>
    )
}

export default ComponentPage

export const query = graphql`
query MyQuery($slug: String!) {
    contentfulComponentPage(slug: { eq: $slug }) {
        slug
    }
}
`